
import { defineComponent } from 'vue';
import RunningSessions from '@/components/admin/RunningSessions.vue';
import PlayerMachinesStatus from '@/components/admin/PlayerMachinesStatus.vue';

export default defineComponent({
  name: 'Admin',
  components: {
    RunningSessions,
    PlayerMachinesStatus,
  },
});
