
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import ListCard from '@/components/ListCard.vue';
import { ListCardItem } from '@/models/list-card-item';
import { PixelFrontEndContainerInfoModel } from '@/models/pixel-frontend-container-info-model';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';
import moment from 'moment';

export default defineComponent({
  name: 'RunningSessions',
  components: {
    ListCard,
    HttpExtendedResultAlert,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  mounted() {
    this.loadRunningSession();
  },

  data: () => ({
    runningSessions: [] as PixelFrontEndContainerInfoModel[],
    loading: false,
    error: undefined as HttpExtendedResult | undefined,
  }),

  computed: {
    runningSessionsList(): ListCardItem[] {
      return this.runningSessions.map(runningSession => this.fillRunningSessionListCardItem(runningSession));
    },
  },

  methods: {
    onRunningSessionClicked(item: ListCardItem) {
      const runningSession = this.runningSessions.find(runningSession => runningSession.presentationSessionId === +item.id);

      if (runningSession)
        this.$router.push({ name: 'Meet', query: { joinToken: runningSession.sessionJoinToken }});
    },

    onRunningSessionStopClicked(item: ListCardItem) {
      const sessionId = +item.id;

      this.store.dispatch(ActionTypes.STOP_PRESENTATION_SESSION, sessionId).then(
        () => this.loadRunningSession(),
        error => this.error = error);
    },

    onRunningSessionRefreshClicked() {
      this.loadRunningSession();
    },

    loadRunningSession() {
      this.loading = true;

      this.store.dispatch(ActionTypes.GET_RUNNING_PRESENTATION_SESSIONS).then(
        runningSessions => this.onLoadingSuccess(runningSessions),
        error => this.onLoadingError(error)
      );
    },

    onLoadingSuccess(runningSessions: PixelFrontEndContainerInfoModel[]) {
      this.error = undefined;
      this.loading = false;

      this.runningSessions = runningSessions
        .sort((s1, s2) => {
          if (s1.createdAt > s2.createdAt)
            return 1;
          else if (s1.createdAt === s2.createdAt)
            return 0;
          else
            return -1;
        });
    },

    onLoadingError(error: HttpExtendedResult) {
      this.error = error;
      this.loading = false;
    },

    fillRunningSessionListCardItem(runningSession: PixelFrontEndContainerInfoModel) {
      const subTitle = 'Since: ' +
        runningSession.createdAt.toLocaleDateString() + ' ' +
        runningSession.createdAt.toLocaleTimeString() + ' (' +
        moment(runningSession.createdAt).fromNow() + ')';

      return new ListCardItem(
        runningSession.presentationSessionId.toString(),
        runningSession.userName,
        subTitle);
    },
  },
});
