<template>
  <ListCard title="Player machines status" icon="server" editIcon="play-circle" addIcon="sync-alt"
            loadMessage="Loading player machines status"
            addMessage="No player machines status available."
            v-bind:showAddHeader="true" v-bind:showAddBody="false" v-bind:showEdit="true"
            v-bind:loading="loading"
            v-bind:items="playerMachinesList"
            @addclicked="onPlayerMachineRefreshClicked"
            @editclicked="onPlayerMachineResetClicked" />

  <HttpExtendedResultAlert v-if="error" v-bind:result="error" class="mt-4" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import ListCard from '@/components/ListCard.vue';
import { ListCardItem } from '@/models/list-card-item';
import { PlayerMachineModel } from '@/models/player-machine-model';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';

export default defineComponent({
  name: 'PlayerMachinesStatus',
  components: {
    ListCard,
    HttpExtendedResultAlert,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  mounted() {
    this.loadPlayerMachinesStatus();
  },

  data: () => ({
    playerMachines: [] as PlayerMachineModel[],
    loading: false,
    error: undefined as HttpExtendedResult | undefined,
  }),

  computed: {
    playerMachinesList(): ListCardItem[] {
      return this.playerMachines.map(playerMachine => this.fillPlayerMachineListCardItem(playerMachine));
    },
  },

  methods: {
    onPlayerMachineResetClicked(/*item: ListCardItem*/) {
      this.store.dispatch(ActionTypes.RESET_PLAYER_MACHINES_STATUS).then(
        () => this.loadPlayerMachinesStatus(),
        error => this.error = error);
    },

    onPlayerMachineRefreshClicked() {
      this.loadPlayerMachinesStatus();
    },

    loadPlayerMachinesStatus() {
      this.loading = true;

      this.store.dispatch(ActionTypes.GET_PLAYER_MACHINES_STATUS).then(
        playerMachines => this.onLoadingSuccess(playerMachines),
        error => this.onLoadingError(error)
      );
    },

    onLoadingSuccess(playerMachines: PlayerMachineModel[]) {
      this.error = undefined;
      this.loading = false;

      this.playerMachines = playerMachines;
    },

    onLoadingError(error: HttpExtendedResult) {
      this.error = error;
      this.loading = false;
    },

    fillPlayerMachineListCardItem(playerMachine: PlayerMachineModel) {
      return new ListCardItem(
        playerMachine.name,
        playerMachine.name,
        'Is offline: ' + playerMachine.isOffline);
    },
  },
});
</script>
