<template>
  <div class="container">
    <h1>Administration</h1>

    <div class="row">
      <div class="col-md mb-4">
        <RunningSessions />
      </div>

      <div class="col-md mb-4">
        <PlayerMachinesStatus />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RunningSessions from '@/components/admin/RunningSessions.vue';
import PlayerMachinesStatus from '@/components/admin/PlayerMachinesStatus.vue';

export default defineComponent({
  name: 'Admin',
  components: {
    RunningSessions,
    PlayerMachinesStatus,
  },
});
</script>
